export const GROUP_HOME_COMPONENTS = {
  MANAGEMENT_WIDGET: 'PROJECT_PRODUCT_MANAGEMENT',
  MEMBER_WIDGET: 'GROUP_MEMBER_MANAGEMENT',
  RANKING_WIDGET: 'CUMULATIVE_PRODUCT_SALES_RANKING',
  SETTLEMENT_WIDGET: 'MONTHLY_SALES_PROJECTION',
  RELEASE_WIDGET: 'RELEASE_PLAN',
  REVIEW_WIDGET: 'STORE_REVIEW',
  COMMUNITY_WIDGET: 'STORE_COMMUNITY',
  LOUNGE_WIDGET: 'LOUNGE_HASHTAG',
  WISHLIST_WIDGET: 'STORE_WISHLIST',
  STATISTICS_WIDGET: 'PRODUCT_STATISTICS'
};
