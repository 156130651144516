<template>
  <div v-if="banners.length > 0" class="mt-[10rem]">
    <swiper
      v-model="currentPage"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false
      }"
      class="flex gap-24"
      direction="horizontal"
      :slidesPerView="SLIDES_PER_PAGE"
      :spaceBetween="24"
      :navigation="{ nextEl: '#notice-swiper-right', prevEl: '#notice-swiper-left' }"
      :modules="[Autoplay, Navigation, Pagination]"
      cssMode
      @swiper="getSwiperRef"
    >
      <swiper-slide v-for="(banner, idx) in banners" :key="`banner_${idx}`">
        <div class="flex-1 h-[23.4rem] rounded-[4rem] overflow-hidden relative">
          <safe-html
            tag="div"
            class="absolute bottom-[3rem] left-[3rem] right-[3rem] text-3xl leading-2xl font-bold text-white w-200 max-h-92 break-all overflow-hidden"
            :html="banner?.title"
          />
          <img :src="banner?.imageUrl" alt="" class="w-full h-full object-cover" />
          <a
            v-if="banner.target !== 'DISABLED'"
            :href="banner?.targetUrl"
            class="absolute inset-0"
            :target="getTargetTagA(banner?.target)"
          ></a>
        </div>
      </swiper-slide>
    </swiper>
    <s-pagination
      v-if="isMultipleBanners"
      v-model="currentPage"
      class="!py-32"
      :pageCount="pageCount"
      displayFirstAndLastButton
      @click="onChangePage"
    />
  </div>
</template>

<script setup lang="ts">
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper/types/index.d.ts';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { fetchPartnerBannerApi } from '@/apis/group-home.api';
import SafeHtml from '@/components/common/safe-html.vue';
import type { PartnerBannersRequest } from '@/types/group-home/group-home-request.type';
import type { PartnerBannerResponse } from '@/types/group-home/group-home-response.type';

const SLIDES_PER_PAGE = 2;
const MAX_BANNERS_PER_CALL = 100;
const MAX_BANNERS_PER_PAGE = 10;

const route = useRoute();
const groupId = route.params.groupId as string;

const mySwiper = ref<SwiperType>();
const banners = ref<PartnerBannerResponse[]>([]);
const totalElements = ref(1);
const pageOffset = ref<number>(1);

const currentPage = computed(() => (mySwiper?.value?.realIndex ?? 0) + 1);
const pageCount = computed(() => totalElements.value - SLIDES_PER_PAGE + 1);
const isMultipleBanners = computed(() => banners.value.length > 2);
const isAtLastPage = computed(() => currentPage.value === MAX_BANNERS_PER_CALL * pageOffset.value);

const shouldSkipUpdate = (newIndex?: number, oldIndex?: number) => {
  return (
    (newIndex === 0 && oldIndex === undefined) || newIndex === undefined || oldIndex === undefined
  );
};

const getSwiperRef = (swiperInstance: SwiperType) => {
  mySwiper.value = swiperInstance;
};

const getTargetTagA = (target: string) => {
  if (target === 'BLANK') {
    return '_blank';
  }
  return '_self';
};

const onChangePage = ({ el, number }: { el: string; number: number }) => {
  if (mySwiper.value) {
    if (el === 'next') {
      mySwiper.value.slideNext();
      return;
    }

    if (el === 'prev') {
      mySwiper.value.slidePrev();
      return;
    }

    if (el === 'last') {
      const offset = Math.floor(banners.value.length / MAX_BANNERS_PER_PAGE);
      mySwiper.value.slideTo(offset * MAX_BANNERS_PER_PAGE);
      return;
    }

    mySwiper.value.slideTo(number - 1);
  }
};

const getBanners = async (page: number) => {
  const query: PartnerBannersRequest = {
    groupId,
    requestTime: new Date().getTime(),
    page,
    size: MAX_BANNERS_PER_CALL
  };

  const defaultBanners = await fetchPartnerBannerApi(query);
  const languageBanners = await fetchPartnerBannerApi(query, false);
  if (!languageBanners || !defaultBanners) {
    return;
  }

  banners.value = languageBanners
    ? languageBanners.contents.map((l: PartnerBannerResponse, index: number) => {
      if (!l) {
        return defaultBanners.contents[index];
      }
      return l;
    })
    : [];

  totalElements.value = languageBanners.totalElements ?? 1;
  pageOffset.value = languageBanners.page;
};

onMounted(() => {
  if (mySwiper.value) {
    mySwiper.value.navigation.init();
    mySwiper.value.navigation.update();
  }
});

watch(
  () => mySwiper?.value?.realIndex,
  (newRealIndex?: number, oldIndex?: number) => {
    if (shouldSkipUpdate(newRealIndex, oldIndex)) {
      return;
    }

    if (isAtLastPage.value && newRealIndex! > oldIndex!) {
      pageOffset.value++;
      getBanners(pageOffset.value);
    }
  }
);

getBanners(1);
</script>
<style scoped lang="scss">
.swiper-slide:last-child {
  margin-right: 0;
}
</style>
