<template>
  <div
    v-if="!isLoading"
    class="h-[4.6rem] rounded-2xl bg-blue50 flex items-center px-24 mb-40 mt-16"
  >
    <swiper
      class="w-full h-full"
      :slidesPerView="1"
      direction="vertical"
      :autoplay="{ delay: 4000, disableOnInteraction: false }"
      :modules="[Autoplay, Navigation, Pagination]"
      :navigation="{ nextEl: '#notice-swiper-right', prevEl: '#notice-swiper-left' }"
      @swiper="getSwiperRef"
    >
      <template v-if="notices.length > 0">
        <swiper-slide v-for="notice in notices" :key="notice.noticeId" class="!flex items-center">
          <a
            href=""
            class="inline-flex gap-8 text-md text-on-surface-elevation-2 leading-lg"
            @click.prevent="goToNoticeDetailPage(notice.noticeId)"
          >
            <span class="shrink-0 font-medium text-[1.4rem]">{{ notice.label }}</span>
            <span class="line-clamp-1 font-regular pr-[0.1rem] text-[1.4rem]">{{
              notice.title
            }}</span>
          </a>
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide class="!flex items-center">
          <a
            href=""
            class="inline-flex gap-8 text-md text-on-surface-elevation-2 leading-lg"
            @click.prevent
          >
            <span class="shrink-0 font-medium">{{ $t('studio.notice.category_stu_notice') }}</span>
            <span class="line-clamp-1 font-regular">{{ $t('studio.notice.none_guide') }}</span>
          </a>
        </swiper-slide>
      </template>
    </swiper>

    <div class="flex gap-20 items-center ml-auto">
      <div class="flex gap-16 items-center ml-auto">
        <template v-if="isDisabledNextPre">
          <button
            id="disable-prev"
            :disabled="isDisabledNextPre"
            type="button"
            class="swiper-left text-on-surface-elevation-1 disabled:text-disabled-variant-1"
          >
            <s-icon icon="ic-v2-control-arrow-left-line" size="xl" />
          </button>
          <i class="w-[.1rem] h-16 bg-inverse-elevation-3 block"></i>
          <button
            id="disable-next"
            :disabled="isDisabledNextPre"
            type="button"
            class="text-on-surface-elevation-1 disabled:text-disabled-variant-1"
          >
            <s-icon icon="ic-v2-control-arrow-right-line" size="xl" />
          </button>
        </template>
        <button
          v-if="notices.length > 0"
          id="notice-swiper-left"
          type="button"
          class="swiper-left text-on-surface-elevation-1 disabled:text-disabled-variant-1"
        >
          <s-icon icon="ic-v2-control-arrow-left-line" size="xl" />
        </button>
        <i v-if="!isDisabledNextPre" class="w-[.1rem] h-16 bg-inverse-elevation-3 block"></i>
        <button
          v-if="notices.length > 0"
          id="notice-swiper-right"
          type="button"
          class="swiper-right text-on-surface-elevation-1 disabled:text-disabled-variant-1"
        >
          <s-icon icon="ic-v2-control-arrow-right-line" size="xl" />
        </button>
      </div>
      <s-text
        v-if="notices.length > 0"
        as="a"
        role="btn2"
        href=""
        class="text-nowrap text-on-surface-elevation-1 text-[1.4rem]"
        @click.prevent="goToNoticePage"
      >
        {{ $t('studio.notice.see_more_btn') }}
      </s-text>
    </div>
  </div>
</template>
<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/pagination';

import { storeToRefs } from 'pinia';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper/types/index.d.ts';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, onMounted, ref } from 'vue';

import { NOTICE_PAGE_URL } from '@/constants/url.const';
import { useNoticeStore } from '@/stores/notice.store';
import { redirectTo } from '@/utils/common.util';

const mySwiper = ref<SwiperType>();

const noticeStore = useNoticeStore();
const { fetchNoticeList, fetchLatestNotice } = noticeStore;
const { pinnedNotices: notices } = storeToRefs(noticeStore);

const isLoading = ref(true);

const isDisabledNextPre = computed(() => notices.value.length <= 1);

const init = async () => {
  const params = {
    size: 24,
    page: 1
  };

  await Promise.all([fetchNoticeList(params), fetchLatestNotice()]);
  setTimeout(() => {
    isLoading.value = false;
  }, 200);
};

const getSwiperRef = (swiperInstance: SwiperType) => {
  mySwiper.value = swiperInstance;
};

const goToNoticePage = () => {
  redirectTo(NOTICE_PAGE_URL);
};

const goToNoticeDetailPage = (id: string) => {
  redirectTo(`${NOTICE_PAGE_URL}/${id}`);
};

onMounted(() => {
  if (mySwiper.value) {
    mySwiper.value.navigation.init();
    mySwiper.value.navigation.update();
  }
});

init();
</script>
