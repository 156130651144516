<template>
  <notice-header />
  <template v-if="!isEdit">
    <div class="mt-8 flex gap-12 items-center">
      <h2 class="text-[4.2rem] leading-[7rem] inline-block">
        <span class="font-medium text-[#92B1FF] whitespace-nowrap mr-12">{{
          $t('studio.group.home.title_group_name')
        }}</span>
        <span class="font-bold text-on-surface-elevation-1 break-all">{{
          selectedGroupInfo?.groupName
        }}</span>
        <button
          v-if="isOwner"
          type="button"
          class="w-48 h-48 inline-flex justify-center items-center rounded-full hover-interaction-overlay ml-12"
          @click="showEditGroupDialog"
        >
          <s-icon
            icon="ic-v2-community-pencil-line"
            size="4xl"
            class="flex text-on-surface-elevation-1"
          />
        </button>
      </h2>
    </div>
    <div class="mt-8 flex gap-8">
      <a class="cursor-pointer" @click="redirectTo(MEMBER_MNG_PAGE_URL)">
        <button
          type="button"
          class="h-32 px-12 flex gap-4 items-center text-on-surface-elevation-2 bg-[#E7EEFF] rounded-full hover-interaction-overlay"
        >
          <s-icon icon="ic-v2-community-person-line" size="xl" class="flex" />
          <span class="text-xs font-medium leading-xs">{{
            $t('studio.group.home.label_group_mem_count', {
              grMemCount: selectedGroupInfo?.memberCount
            })
          }}</span>
        </button>
      </a>

      <s-tooltip
        arrow
        :content="`<p class='text-center'>${$t('studio.group.home.language_tool_tip')}</p>`"
        :duration="0"
        :distance="8"
        useFlip
        flipOnUpdate
        placement="bottom"
        trigger="mouseenter focus"
        :theme="'studio-tooltip'"
        :zIndex="600"
        :allowHTML="true"
      >
        <template #target>
          <button
            :disabled="!isOwner"
            type="button"
            class="h-32 px-12 flex gap-4 items-center text-on-surface-elevation-2 bg-[#E7EEFF] rounded-full disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1 hover-interaction-overlay"
            @click="showLanguageDialog"
          >
            <s-icon icon="ic-v2-community-public-line" size="xl" class="flex" />
            <span class="text-xs font-medium leading-xs">{{ $t(currentLanguage.langTitle) }}</span>
          </button>
        </template>
      </s-tooltip>
    </div>
    <div class="mt-24 flex gap-16 justify-end">
      <p class="text-md text-on-surface-elevation-4 leading-md font-medium">
        {{ $t('studio.group.home.dash_board_setting') }}
      </p>
      <a
        href=""
        class="flex items-center gap-4 text-on-surface-elevation-1 text-md leading-sm font-medium group"
        @click.prevent="showSettingProductsDialog"
      >
        <s-icon icon="ic-v2-object-game-line" size="3xl" class="flex" />
        <span class="group-hover:underline underline-offset-2">{{
          $t('studio.group.home.select_product')
        }}</span>
      </a>
      <a
        href=""
        class="flex items-center gap-4 text-on-surface-elevation-1 text-md leading-sm font-medium group"
        @click.prevent="handleEditDashboard"
      >
        <s-icon icon="ic-v2-community-view-card-line" size="3xl" class="flex" />
        <span class="group-hover:underline underline-offset-2">{{
          $t('studio.group.home.edit_list')
        }}</span>
      </a>
    </div>
  </template>
  <st-title
    v-else
    :navItems="[
      {
        name: $t('studio.group.home.edit_list.breadcrumb1'),
        url: 'home'
      },
      {
        name: $t('studio.group.home.edit_list.breadcrumb2')
      }
    ]"
    :title="$t('studio.group.home.edit_list.title')"
    :titleDesc="$t('studio.group.home.edit_list.guide')"
    @onClick="backToHome"
  />
  <widget-board
    v-if="dashboardComponents.length > 0"
    :key="`dashboardComponents-${settingProductDialogUpdatingCount}`"
    :components="dashboardComponents"
    :isEdit="isEdit"
    @cancel="isEdit = false"
    @edited="isEdit = false"
  />
  <p
    v-show="!isEdit"
    class="mt-16 text-sm leading-md text-on-surface-elevation-4 flex gap-4 items-center"
  >
    <s-icon icon="ic-v2-control-refresh-line" size="xl" class="flex" />
    {{ $t('studio.group.home.refresh_info') }}
  </p>
  <partner-banner v-show="!isEdit" />
  <!-- Todo: what if !isSelectedProduct -->
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchLatestSignupApprovalToGroupApi } from '@/apis/group.api';
import stTitle from '@/components/common/st-title.vue';
import GroupFormDialog from '@/components/group/group-form-dialog.vue';
import LanguageSettingDialog from '@/components/home/language-setting-dialog.vue';
import NoticeHeader from '@/components/home/notice-header.vue';
import PartnerBanner from '@/components/home/partner-banner.vue';
import SettingProductsDialog from '@/components/home/setting-products-dialog.vue';
import WidgetBoard from '@/components/home/widget-board.vue';
import { useApp } from '@/composables/useApp';
import { showConfirm, showDialog } from '@/composables/useDialog';
import { TRANSLATE_LANGUAGES_SETTINGS } from '@/constants/common.const';
import { HOME_PAGE_URL, MEMBER_MNG_PAGE_URL } from '@/constants/url.const';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useGroupHomeStore } from '@/stores/group-home.store';
import { useUserStore } from '@/stores/user.store';
import type { LanguageModel } from '@/types/common/common.type';
import type { RecentlyApprovedGroupResponse } from '@/types/group-home/group-home-response.type';
import { redirectTo } from '@/utils/common.util';

definePageMeta({
  menuAuthId: MenuAuthorityIds.GROUP_HOME
});

const { t } = useI18n();
const app = useApp();

const { isLoading, isFirstEntryHome } = storeToRefs(useAppStore());

const userStore = useUserStore();
const { selectedGroupId, selectedGroupInfo, isOwner } = storeToRefs(userStore);
const { fetchSimpleMyInfos } = userStore;

const groupHomeStore = useGroupHomeStore();
const { selectedProducts, dashboardComponents } = storeToRefs(groupHomeStore);
const { getGroupDashboardComponents, getGroupDashboardSelectedProducts } = groupHomeStore;

const isEdit = ref<boolean>(false);
const settingProductDialogUpdatingCount = ref<number>(0);
const lastJoinedGroup = ref<RecentlyApprovedGroupResponse>();

const currentLanguage = computed<LanguageModel>(() => ({
  langCode: selectedGroupInfo.value?.languageCd || '',
  langTitle:
    TRANSLATE_LANGUAGES_SETTINGS.find(
      (lang: LanguageModel) => lang.langCode === selectedGroupInfo.value?.languageCd
    )?.langTitle || ''
}));

const showEditGroupDialog = async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  (await showDialog({
    component: shallowRef(GroupFormDialog),
    props: {
      isEditMode: true
    },
    severity: 'info'
  })) as string;
};

const backToHome = () => {
  isEdit.value = false;
};

const handleEditDashboard = async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  isEdit.value = true;
};

const showLanguageDialog = async () => {
  await showDialog({
    component: shallowRef(LanguageSettingDialog),
    props: {
      currentLanguage: currentLanguage.value
    }
  });
};

const showSettingProductsDialog = async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  await showDialog({
    component: shallowRef(SettingProductsDialog),
    props: {
      currentProducts: selectedProducts.value
    }
  });
  settingProductDialogUpdatingCount.value = settingProductDialogUpdatingCount.value === 0 ? 1 : 0;
};

const showLastJoinedPopup = async () => {
  const result = await fetchLatestSignupApprovalToGroupApi();
  if (!result) {
    return;
  }
  lastJoinedGroup.value = result;
};

const init = async () => {
  await Promise.all([
    getGroupDashboardComponents(selectedGroupId.value, false),
    getGroupDashboardSelectedProducts(selectedGroupId.value)
  ]);

  showLastJoinedPopup();
};

init();

watch(
  () => [isLoading.value, lastJoinedGroup.value?.groupName],
  async () => {
    if (!isFirstEntryHome.value && !isLoading.value && lastJoinedGroup.value?.groupName) {
      isFirstEntryHome.value = true;

      const confirm = await showConfirm({
        content: `${t('studio.stu_logged_in.pre_grp_join.grp_join_req_accepted_msg')}<br/>${t(
          'studio.stu_logged_in.pre_grp_join.grp_go_to_grp_pg_guide'
        )}`,
        confirmLabel: t('studio.stu_logged_in.pre_grp_join.btn_go_to_grp_pg'),
        cancelLabel: t('studio.group.home.join_req_accepted_popup_close_btn'),
        cancelVariant: 'outline'
      });
      if (!confirm) {
        return;
      }
      await fetchSimpleMyInfos(lastJoinedGroup.value?.groupId);
      await redirectTo(HOME_PAGE_URL, { groupId: lastJoinedGroup.value?.groupId, external: true });
    }
  }
);
</script>

<style lang="scss" src="~~/assets/css/index-page.css" />
