<!-- D : Studio 공지사항 상세 보기 팝업 -->
<template>
  <s-dialog to="stds-dialog-setting-products" size="md" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="!h-[64rem]">
      <st-dialog-header @clickClose="onClose">
        <span class="flex items-center gap-8">
          <span class="font-bold">{{ $t('studio.group.home.select_product.title') }}</span>
          <span class="text-xs leading-xs text-placeholder">
            <span class="font-medium text-on-surface-elevation-2">{{
              selectedProducts?.length || 0
            }}</span>/{{ totalElements }}
          </span>
        </span>
      </st-dialog-header>
      <s-dialog-content class="h-full flex flex-col py-8 gap-16">
        <safe-html
          class="text-md leading-lg font-regular text-on-surface-elevation-2 shrink-0"
          :html="$t('studio.group.home.select_product.guide')"
          tag="p"
        />
        <div
          v-click-outside="
            () => {
              isSearchLayerOpen = false;
            }
          "
          class="relative shrink-0"
        >
          <input-text
            v-model="searchText"
            :placeholder="$t('studio.group.home.select_product.search_place_holder')"
            searchable
            size="lg"
            variant="outline"
            @click="onClickSearchLayer"
            @search="onSearch"
            @clear="onClear"
          />
          <div
            v-end-scroll="onLoadMore"
            class="absolute top-full inset-x-0 bg-surface-layer rounded-xl shadow-2 max-h-[22rem] py-16 studio-scrollbar-4 border-1 border-solid border-border"
            :class="isSearchLayerOpen ? '' : 'hidden'"
          >
            <div
              v-show="checkBoxData.length === 0"
              class="h-[8.8rem] flex items-center justify-center pl-20"
            >
              <p class="text-md leading-lg font-regular text-on-surface-elevation-2 text-center">
                {{ $t('studio.group.home.select_product.search_no_result_msg') }}
              </p>
            </div>
            <checkbox-group
              v-show="!!checkBoxData.length"
              :options="checkBoxData"
              name="selectedProducts"
              class="flex flex-col gap-8 pl-16 pr-4"
              size="sm"
              align="middle"
            />
          </div>
        </div>

        <div
          v-if="selectedProducts?.length === 0"
          class="mt-16 flex-1 flex justify-center items-center"
        >
          <p class="text-md leading-lg font-regular text-on-surface-elevation-2 text-center">
            {{ $t('studio.group.home.select_product.search_no_select') }}
          </p>
        </div>
        <template v-else>
          <sortable
            :key="`sortable-${sortedData?.length}-${products.length}`"
            :list="sortedData"
            itemKey="productNo"
            :options="{ ...options }"
            class="mt-16 flex-1 flex flex-col gap-8"
            @end="onUpdate"
          >
            <template #item="{ element }">
              <product-item
                v-if="element.productName"
                :key="element.productNo"
                :dataId="element.productNo"
                class="product-draggable"
                :productName="element.productName"
                @remove="removeProduct(element.productNo)"
              />
            </template>
          </sortable>
        </template>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button class="w-full" @click="confirm">
          {{ $t('studio.common.popup_case_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-setting-products" />
</template>

<script setup lang="ts">
import { unionBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import type { SortableEvent, SortableOptions } from 'sortablejs';
import type { AutoScrollOptions } from 'sortablejs/plugins';
import { Sortable } from 'sortablejs-vue3';
import { useFieldValue, useForm, useSetFieldValue } from 'vee-validate';
import { computed, ref, toRefs } from 'vue';

import { updateGroupDashboardProductsApi } from '@/apis/group-home.api';
import { searchProductsApi } from '@/apis/products.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import ProductItem from '@/components/home/product-item.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import InputText from '@/components/validation/input-text.vue';
import { PLAN_STATUS } from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import { useGroupHomeStore } from '@/stores/group-home.store';
import { useUserStore } from '@/stores/user.store';
import type { FormOption } from '@/types/common/form.type';
import type { Pagination } from '@/types/common/pagination.type';
import type { DashboardSettingProductRequest } from '@/types/group-home/group-home-request.type';
import type { DashboardSettingProductResponse } from '@/types/group-home/group-home-response.type';
import type { ProductSearchItem } from '@/types/product/product-response.type';

const props = defineProps<{
  currentProducts: DashboardSettingProductResponse[];
}>();

const emit = defineEmits<{
  close: [isUpdated?: boolean];
}>();

const options: SortableOptions | AutoScrollOptions = {
  draggable: '.product-draggable',
  animation: 150,
  easing: 'cubic-bezier(1, 0, 0, 1)',
  ghostClass: 'ghost',
  dragClass: 'drag',
  scroll: true,
  forceFallback: true,
  bubbleScroll: true,
  swapThreshold: 0.65,
  direction: 'vertical'
};

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const groupHomeStore = useGroupHomeStore();
const { getGroupDashboardSelectedProducts } = groupHomeStore;

const { currentProducts } = toRefs(props);

const products = ref<ProductSearchItem[]>([]);
const productsFull = ref<ProductSearchItem[]>([]);
const currentPage = ref(1);
const totalPage = ref(1);
const totalElements = ref(0);
const searchText = ref('');
const paginationSearchText = ref('');
const isSearchLayerOpen = ref<boolean>(false);

useForm<{ selectedProducts: number[] }>({
  initialValues: {
    selectedProducts: [
      ...currentProducts.value.map((product: DashboardSettingProductResponse) => product.productNo)
    ]
  }
});
const selectedProducts = useFieldValue<number[]>('selectedProducts');
const setSelectedProducts = useSetFieldValue<number[]>('selectedProducts');

const checkBoxData = computed<FormOption<number>[]>(() =>
  products.value.map((product: ProductSearchItem) => ({
    label: `${product.projectName} - ${product.productName}`,
    value: product.productNo
  }))
);

const sortedData = computed(() => {
  const mappedProductsFromApi = productsFull.value
    .filter((product: ProductSearchItem) =>
      (selectedProducts.value || []).includes(product.productNo)
    )
    .map((product: ProductSearchItem) => ({
      productNo: product.productNo,
      productName: product.projectName + ' - ' + product.productName
    }));

  const mappedCurrentProducts = currentProducts.value
    .filter((product: DashboardSettingProductResponse) =>
      (selectedProducts.value || []).includes(product.productNo)
    )
    .map((product: DashboardSettingProductResponse) => ({
      productNo: product.productNo,
      productName: product.projectName + ' - ' + product.productName
    }));

  return unionBy(mappedCurrentProducts, mappedProductsFromApi, 'productNo');
});

const moveItemInArray = (from: number, to: number) => {
  if (from === to) {
    return;
  }
  const newField = [...selectedProducts.value];
  const element = newField[from];
  newField.splice(from, 1);
  newField.splice(to, 0, element);
  setSelectedProducts(newField);
};

const onUpdate = (event: SortableEvent) => {
  const oldIndex = event.oldIndex ?? 0;
  const newIndex = event.newIndex ?? 0;
  moveItemInArray(oldIndex, newIndex);
};

const onClose = () => {
  emit('close');
};
const onClickSearchLayer = () => {
  isSearchLayerOpen.value = true;
};

const getReleaseProducts = async (q: string, page: number): Promise<ProductSearchItem[]> => {
  const params = {
    groupId: selectedGroupId.value,
    planStatus: [PLAN_STATUS.PAGE_OPEN, PLAN_STATUS.PRE_PURCHASE, PLAN_STATUS.RELEASE],
    releaseYN: Confirmation.YES,
    q,
    page,
    size: 10
  };

  const result = (await searchProductsApi(params)) as Pagination<ProductSearchItem[]>;
  if (!result || result.contents.length === 0) {
    return [];
  }

  currentPage.value = page;
  totalPage.value = result.totalPages;
  totalElements.value = result.totalElements;
  return result.contents;
};

const onLoadMore = async () => {
  if (currentPage.value >= totalPage.value) {
    return;
  }
  const newProducts = await getReleaseProducts(paginationSearchText.value, currentPage.value + 1);
  const notDuplicateProducts = newProducts.filter(
    (newProduct: ProductSearchItem) =>
      !products.value.some(
        (product: ProductSearchItem) => product.productNo === newProduct.productNo
      )
  );

  products.value = products.value.concat(notDuplicateProducts);
  productsFull.value = unionBy(productsFull.value, products.value, 'productNo');
};

const onSearch = async () => {
  products.value = await getReleaseProducts(searchText.value, 1);
  productsFull.value = unionBy(productsFull.value, products.value, 'productNo');
  paginationSearchText.value = searchText.value;
};

const onClear = async () => {
  onSearch();
};

const confirm = async () => {
  const updateData: DashboardSettingProductRequest[] = (selectedProducts.value || []).map(
    (productNo: number, index: number) => ({
      productNo,
      sortNo: index + 1
    })
  );
  await updateGroupDashboardProductsApi(selectedGroupId.value, updateData);
  await getGroupDashboardSelectedProducts(selectedGroupId.value);

  emit('close', true);
};

const removeProduct = (productNo: number) => {
  const newSelectedProducts = selectedProducts.value.filter(
    (selectedProductNo: number) => selectedProductNo !== productNo
  );
  setSelectedProducts(newSelectedProducts);
};

const init = async () => {
  products.value = await getReleaseProducts(paginationSearchText.value, 1);
  productsFull.value = unionBy(productsFull.value, products.value, 'productNo');
};

init();
</script>
