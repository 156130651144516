<template>
  <project-registration v-if="isShowRegistrationBanner" class="mt-24" />
  <sortable
    ref="sortable"
    :list="widgets"
    itemKey="id"
    class="mt-24 grid grid-cols-3 gap-24"
    :options="{ ...options, disabled: !isEdit }"
    @end="onUpdate"
    @start="startDrag"
  >
    <template #item="{ element }">
      <component
        :is="groupHomeComponents[element.id]"
        v-if="element.isActive || isEdit"
        :key="`${element.id}-${selectedProducts.length}`"
        :ref="(ref: any) => widgetsRefs[element.id] = ref"
        class="draggable"
        :dragUse="isEdit"
        :isChecked="element.isActive"
        :isDisabled="element.isDisabled"
        :class="element.isDragging ? 'opacity-0' : ''"
        :products="selectedProducts"
        @checked="element.isActive = $event"
        @click="(e: Event) => handleClickWidget(e, element.id)"
      />
    </template>
  </sortable>
  <div v-if="isEdit" class="mt-40 flex justify-center gap-16">
    <s-button variant="outline" size="lg" class="!min-w-160" @click="onCancel">
      {{ $t('studio.common.popup_case_cancel_btn') }}
    </s-button>
    <s-button variant="secondary" size="lg" class="!min-w-160" @click="onSave">
      {{ $t('studio.common.popup_case_save_btn') }}
    </s-button>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { SortableEvent, SortableOptions } from 'sortablejs';
import type { AutoScrollOptions } from 'sortablejs/plugins';
import { Sortable } from 'sortablejs-vue3';
import { computed, defineAsyncComponent, defineProps, ref, toRefs, watchEffect } from 'vue';

import { updateGroupDashboardComponentApi } from '@/apis/group-home.api';
import ProjectRegistration from '@/components/widgets/project-registration.vue';
import { GROUP_HOME_COMPONENTS } from '@/constants/group-home.const';
import { Confirmation } from '@/enums/common.enum';
import { useGroupHomeStore } from '@/stores/group-home.store';
import { useUserStore } from '@/stores/user.store';
import type { DashboardWidget } from '@/types/group-home/group-home-model.type';
import type { DashboardComponentResponse } from '@/types/group-home/group-home-response.type';

const props = defineProps<{
  components: DashboardComponentResponse[];
  isEdit?: boolean;
  projectNumber?: number;
}>();

const emit = defineEmits<{
  cancel: [v: void];
  edited: [v: void];
}>();

const { isEdit } = toRefs(props);

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const groupHomeStore = useGroupHomeStore();
const { selectedProducts, projectsProductsCounts } = storeToRefs(groupHomeStore);
const { getGroupProjectsProductsCounts } = groupHomeStore;

const options: SortableOptions | AutoScrollOptions = {
  draggable: '.draggable',
  animation: 150,
  easing: 'cubic-bezier(1, 0, 0, 1)',
  ghostClass: 'ghost',
  dragClass: 'drag',
  scroll: true,
  forceFallback: true,
  bubbleScroll: true,
  swapThreshold: 0.65,
  direction: 'vertical'
};

const widgetsRefs = ref<any>([]);
const widgets = ref<DashboardWidget[]>([]);

const isShowRegistrationBanner = computed<boolean>(
  () => !isEdit.value && projectsProductsCounts.value?.projectCount === 0
);

const handleClickWidget = (e: Event, id: string) => {
  if (isEdit.value) {
    e.preventDefault();
  } else {
    redirectToPage(id);
  }
};
const redirectToPage = (id: string) => {
  const currentWidget = widgetsRefs.value[id as keyof typeof widgetsRefs.value];
  if (!currentWidget.hasRedirectException) {
    currentWidget.redirectToTargetPage();
  }
};

const moveItemInArray = (from: number, to: number) => {
  if (from === to) {
    return;
  }
  const element = widgets.value[from];

  widgets.value.splice(from, 1);
  widgets.value.splice(to, 0, element);
};

const onUpdate = (event: SortableEvent) => {
  const oldIndex = event.oldIndex ?? 0;
  widgets.value[oldIndex].isDragging = false;
  const newIndex = event.newIndex ?? 0;
  if (oldIndex === newIndex) {
    return;
  }
  moveItemInArray(oldIndex, newIndex);
};

const startDrag = (event: SortableEvent) => {
  const draggingIndex = event.oldIndex ?? 0;
  widgets.value[draggingIndex].isDragging = true;
};

const groupHomeComponents = {
  [GROUP_HOME_COMPONENTS.MANAGEMENT_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/management-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.RELEASE_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/release-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.STATISTICS_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/statistic-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.SETTLEMENT_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/settlement-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.COMMUNITY_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/community-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.RANKING_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/rank-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.REVIEW_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/review-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.LOUNGE_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/lounge-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.WISHLIST_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/wishlist-widget.vue')
  ),
  [GROUP_HOME_COMPONENTS.MEMBER_WIDGET]: defineAsyncComponent(
    () => import('@/components/widgets/member-widget.vue')
  )
};

const mapToWidget = (components: DashboardComponentResponse[]): DashboardWidget[] => {
  const sorted = components.sort(
    (a: DashboardComponentResponse, b: DashboardComponentResponse) => a.sortNo - b.sortNo
  );
  return sorted.map((component: DashboardComponentResponse) => ({
    id: component.componentType,
    isActive: component.activeYn === Confirmation.YES,
    isDisabled: component.defaultYn === Confirmation.YES,
    isDragging: false,
    groupDashboardComponentNo: component.groupDashboardComponentNo,
    dashboardComponentNo: component.dashboardComponentNo
  }));
};

const onCancel = async () => {
  widgets.value = mapToWidget(props.components);
  emit('cancel');
};

const onSave = async () => {
  await updateGroupDashboardComponentApi(selectedGroupId.value, widgets.value);
  emit('edited');
};

const init = async () => {
  widgets.value = mapToWidget(props.components);
  getGroupProjectsProductsCounts(selectedGroupId.value);
};

init();

watchEffect(() => {
  widgets.value = mapToWidget(props.components);
});
</script>

<style lang="scss">
.sortable-chosen.sortable-fallback.drag {
  @apply border-brand-primary border-[.2rem] border-solid;
  opacity: 1 !important;
}
</style>
