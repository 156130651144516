<!--4-1-2-popup-->
<template>
  <s-dialog to="stds-dialog-overview" size="md" :open="true">
    <s-dialog-overlay @click="onClose" />
    <s-dialog-panel>
      <s-dialog-content>
        <s-dialog-content-body>
          <h2 class="text-2xl font-bold leading-xl text-on-surface-elevation-1">
            {{ $t('studio.group.home.language_standard_setting') }}
          </h2>
          <p class="text-md leading-lg text-on-surface-elevation-2 font-regular">
            <span class="font-medium text-brand-primary">{{
              $t('studio.group.home.language_standard_guide1')
            }}</span><br />
            {{ $t('studio.group.home.language_standard_guide2') }}
          </p>
          <s-radio-group
            v-model="selectedLanguageId"
            class="mt-16 flex flex-col gap-8"
            name="enabled"
          >
            <s-radio
              v-for="(lang, index) in TRANSLATE_LANGUAGES_SETTINGS"
              :id="`radio-${index}`"
              :key="lang.langCode"
              :value="lang.langCode"
              size="sm"
              align="middle"
            >
              {{ $t(lang.langTitle) }}
            </s-radio>
          </s-radio-group>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button class="w-full" variant="outline" @click="onClose">
          {{ $t('studio.common.popup_case_cancel_btn') }}
        </s-button>
        <s-button class="w-full" @click="confirm">
          {{ $t('studio.group.home.language_standard_change') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-overview" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { modifyGroupApi } from '@/apis/group.api';
import { TRANSLATE_LANGUAGES_SETTINGS } from '@/constants/common.const';
import { useUserStore } from '@/stores/user.store';
import type { LanguageModel } from '@/types/common/common.type';

const userStore = useUserStore();
const { fetchSimpleMyInfos } = userStore;
const { selectedGroupId } = storeToRefs(userStore);
const props = defineProps<{
  currentLanguage: LanguageModel;
}>();

const currentLanguage = ref(props.currentLanguage);

const selectedLanguageId = ref(currentLanguage.value.langCode);

const emit = defineEmits<{
  close: [selectedLanguage?: LanguageModel];
}>();

const confirm = async () => {
  if (selectedLanguageId.value === currentLanguage.value.langCode) {
    emit('close');
    return;
  }

  await modifyGroupApi(selectedGroupId.value, { languageCd: selectedLanguageId.value });
  await fetchSimpleMyInfos();
  emit('close');
};

const onClose = () => {
  emit('close');
};
</script>
